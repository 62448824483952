import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { slugify } from '../helpers'

const BlogPage = (props) => {
  const posts = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            content {
              childMarkdownRemark {
                html
                timeToRead
              }
            }
            cover {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            intro
            title
            requirements
            id
          }
        }
      }
    }
  `)
  const articles = posts.allContentfulArticle.edges

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="container mx-auto flex flex-col">
        {articles.map(({ node: article }) => (
          <div key={article.id} className="flex items-center justify-center text-base mb-16 lg:mb-8 flex-col lg:flex-row">
            <div className="p-0 lg:p-4 min-w-xs ">
              <Img className="rounded h-56" fluid={article.cover.localFile.childImageSharp.fluid} />
            </div>
            <div className="px-12 lg:p-4 max-w-md">
              <p className="mb-4 font-bold text-lg">{article.title}</p>
              <p className="mb-8 hidden md:block ">{article.intro}</p>
              <Link to={'/articles/' + slugify(article.title)}
                className=" text-sm w-64 bg-brand-800 py-2 px-8 rounded text-center text-white font-bold">Read More</Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>

  )
}
export default BlogPage
